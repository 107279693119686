<script>
import Form, { formCreate } from '../../../../../../../components/form';
import request from '../../../../../../../utils/request';

import Trajectory from './components/trajectory.vue';

formCreate.component('Trajectory', Trajectory);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    // 获取日进度详情数据
    getDailyProgressDetail() {
      const { row } = this.formConfig;
      const requestUrl = '/sfa/sfaVisitComplete/getSfaVisitCompleteDaily';
      const param = {
        date: row.visitDate,
        posCode: row.visitPosCode,
        userName: row.visitUserName,
      };
      request.get(requestUrl, param).then((res) => {
        this.setValue({
          trajectory: res.result,
        });
        const planCompleteVisitNum = this.getRule('planCompleteVisitNum');
        const tempArr = res.result.planInfoList || [];
        planCompleteVisitNum.value = tempArr.length;
      });
    },
  },
  async created() {
    const rule = await this.getFormRule('sfa_visitManage_dayProgress_form');
    if (this.formConfig.type === 'view') {
      rule.forEach((item) => {
        const v = item;
        v.props = {
          ...v.props,
          readonly: true,
          disabled: false,
        };
      });
    }
    if (this.formConfig.type === 'view' && this.formConfig.id) { // 查看详情
      this.setValue(this.formConfig.row);
    }
    this.getDailyProgressDetail();
  },
};
</script>
