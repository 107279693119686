<template>
  <div class="trajectory-container">
    <div id="trajectory-map" class="trajectory-map">
      <div class="trajectory-map-legend">
          <div class="trajectory-map-legend-item">
            <div class="circle circle-green"></div>
            <div>实际路线</div>
          </div>
          <div class="trajectory-map-legend-item">
            <div class="circle circle-blue"></div>
            <div>计划路线</div>
          </div>
      </div>
    </div>
    <div class="trajectory-path">
      <div class="trajectory-title">员工拜访轨迹列表，共{{ realInfoList.length || 0 }}条记录</div>
      <div class="trajectory-list-container">
        <template v-for="(item, index) in realInfoList">
          <div class="trajectory-list" :key="index" @click="moveView(item)">
            <div class="trajectory-list-left">
              <i class="el-icon-location"></i>
              <div class="trajectory-list-left-line"></div>
            </div>
            <div class="trajectory-list-right">
              <div>进店时间：{{ item.visitDate }}</div>
              <div>客户名称：{{ item.clientName }} </div>
              <div>进店位置：{{ item.inStoreAddress }}</div>
              <!-- <div>在店时长：{{ item.inStoreTime }} </div>
              <div>偏离客户位置：{{ item.distanceToPlan }} 米</div> -->
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

const appKey = process.env.VUE_APP_TENCENTMAP_KEY;
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.planInfoList = val.planInfoList || [];
          this.realInfoList = val.realInfoList || [];
          // 设置标记点
          this.setMakers((val.planInfoList || []), 0); // 计划
          this.setMakers((val.realInfoList || []), 1); // 实际
          this.drowLine(val.planInfoList || []);
          this.drowLine(this.realInfoList, this.realVisitColor);
        }
      },
    },
  },
  data() {
    return {
      // 计划拜访
      planInfoList: [],
      // 实际拜访
      realInfoList: [],
      realVisitColor: '#77F476',
    };
  },
  async created() {
    await this.syncLoadTMap();
    this.initMap();
  },
  beforeDestroy() {
    this.TMap = null;
    this.map = null;
  },
  methods: {
    moveView(item) {
      this.map.setCenter(new this.TMap.LatLng(item.latitude, item.longitude));
      const info = new this.TMap.InfoWindow({ map: this.map });
      info.close();
      info.open(); // 点击标记打开提示窗
      info.setContent(`
                <div class="mapInfo" style="line-height: 20px; max-width: 300px;">
                  <div>${item.visitRealName}</div>
                  <div>进店时间：${item.visitDate || ''}</div>
                  <div>客户名称：${item.clientName || ''}</div>
                  <div>进店位置：${item.inStoreAddress || ''}</div>
                </div>`); // 设置提示窗内容（这里用到了marker对象中保存的数据）
      info.setPosition(new this.TMap.LatLng(item.latitude, item.longitude)); // 提示窗位置
      setTimeout(() => {
        info.close();
      }, 4000);
    },
    /** @desc  异步加载腾讯地图 */
    syncLoadTMap() {
      return new Promise((resolve, reject) => {
        if (window.qq && window.qq.maps) {
          this.TMap = window.qq.maps;
          resolve(window.qq.maps);
        } else {
          const scriptMap = document.createElement('script');
          scriptMap.type = 'text/javascript';
          scriptMap.async = true;
          scriptMap.src = `https://map.qq.com/api/js?v=2.exp&key=${appKey}&callback=initTencentMap`;
          scriptMap.onerror = reject;
          scriptMap.dataTitle = 'tencentMap';
          document.body.appendChild(scriptMap);

          window.initTencentMap = () => {
            this.TMap = window.qq.maps;
            resolve(window.qq.maps);
          };
        }
      });
    },
    /** @desc 初始化地图 */
    initMap() {
      if (!this.TMap) return;
      const that = this;
      const list = this.realInfoList;
      this.map = new this.TMap.Map(document.getElementById('trajectory-map'), {
        zoom: 14, // 设置地图缩放级别
        scrollwheel: false, // 启用滚动
      });
    },
    /** @desc 设置中心点 */
    setCenter(lat, lng) {
      if (!this.TMap) return;
      if (!this.map) return;
      const latLng = new this.TMap.LatLng(lat, lng);
      this.map.setCenter(latLng);
    },
    /**
     * @desc 设置标记点
     * @param list Array
     * @param type:  0=>计划 1=>实际
     */
    setMakers(list, type) {
      if (!this.TMap) return;
      const that = this;
      if (!list || list.length <= 0) return;
      // 根据type自定义 icon
      const iconPlan = new this.TMap.MarkerImage(require('../../../../../../../../assets/img/map-marker0.png'));
      const iconReal = new this.TMap.MarkerImage(require('../../../../../../../../assets/img/map-marker1.png'));
      // const iconReal = new this.TMap.MarkerImage('https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/center.gif');
      // 不能设置空的坐标为中心
      const centerRes = list.find((item) => typeof item.latitude === 'number' && typeof item.longitude === 'number');
      if (centerRes) {
        this.setCenter(centerRes.latitude, centerRes.longitude); // 设置中心
        const info = new this.TMap.InfoWindow({ map: this.map }); // 添加提示窗
        list.forEach((v, k) => {
        // 去掉 lat 和 long 异常的点
          if (v.latitude && v.longitude) {
            const item = v;
            const visitSort = type === 0 ? item.visitSort : k + 1; // 计划的sort用 visitSort， 实际的用索引
            const content = `<span style='color:#FFFFFF;'>${visitSort || k + 1}</span>`;
            const decoration = new this.TMap.MarkerDecoration(content, new this.TMap.Point(0, -5));
            const marker = new this.TMap.Marker({
              icon: type === 0 ? iconPlan : iconReal,
              position: new this.TMap.LatLng(item.latitude, item.longitude),
              decoration,
              map: this.map,
            }); // 创建标记

            // 将必要的数据存入每一个对应的marker对象
            marker.name = item.visitRealName;
            marker.phone = item.visitUserPhone;
            marker.time = item.visitDate;
            marker.customerName = item.clientName;
            marker.locate = item.clientAddress || item.inStoreAddress;
            this.TMap.event.addListener(marker, 'click', (res) => { // 获取标记的点击事件
              const data = res.target;
              info.open(); // 点击标记打开提示窗
              if (type === 0) { // 计划
                info.setContent(`
          <div class="mapInfo" style="line-height: 20px; max-width: 300px;">
            <div>${data.name}（${data.phone || ''}）</div>
            <div>进店时间：${data.time || ''}</div>
            <div>客户名称：${data.customerName || ''}</div>
            <div>进店位置：${data.locate || ''}</div>
          </div>`); // 设置提示窗内容（这里用到了marker对象中保存的数据）
              } else { // 实际
                info.setContent(`
          <div class="mapInfo" style="line-height: 20px; max-width: 300px;">
            <div>${data.name}</div>
            <div>进店时间：${data.time || ''}</div>
            <div>客户名称：${data.customerName || ''}</div>
            <div>进店位置：${data.locate || ''}</div>
          </div>`); // 设置提示窗内容（这里用到了marker对象中保存的数据）
              }
              info.setPosition(new that.TMap.LatLng(data.position.lat, data.position.lng)); // 提示窗位置
            });
          }
        });
      } else {
        this.$message.error('定位信息异常');
      }
    },
    /** @desc 设置连接线 */
    drowLine(list, color = '#409EFF') {
      if (!this.TMap) return;
      const polyArray = [];
      list.forEach((p) => {
        if (typeof p.latitude === 'number' && typeof p.longitude === 'number') {
          polyArray.push(new this.TMap.LatLng(p.latitude, p.longitude));
        }
      });
      const polyline = new this.TMap.Polyline({
        map: this.map,
        // 折线的路径
        path: polyArray,
        // 折线的颜色
        strokeColor: color,
        // 折线的宽度
        strokeWeight: 6,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.trajectory-container{
  width: 100%;
  display: flex;
  align-items: stretch;
}
.trajectory-map{
  flex: 1;
  min-height: 600px;
  position: relative;
  &-legend{
    position: absolute;
    top: 6px;
    right: 100px;
    z-index: 999;
    width: 160px;
    height: 24px;
    padding: 0 10px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    line-height: 24px;
    color: #FFFFFF;
    &-item{
      flex: 1;
      display: flex;
      align-items: center;
      .circle{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 6px;
      }
      .circle-green{
        background-color: #77F476;
      }
      .circle-blue{
        background-color: #409EFF;
      }
    }
  }
}
.trajectory-path{
  width: 210px;
  min-height: 600px;
  margin-left: 10px;
  background-color: #DDDDDD;
  border-radius: 6px;
  padding: 0 6px;
  color: #999999;
  .trajectory-title{
    line-height: 40px;
    border-bottom: 1px solid #FFFFFF;
    font-size: 13px;
  }
  .trajectory-list-container{
    padding: 10px 0;
  }
  .trajectory-list{
    display: flex;
    align-items: stretch;
    font-size: 12px;
    cursor: pointer;
    &-left{
      display: flex;
      flex-direction: column;
      align-items: center;
      &-line{
        flex: 1;
        width: 4px;
        background-color: #77F476;
        border-radius: 2px;
      }
    }
    &-right{
      flex: 1;
      padding-left: 6px;
      padding-bottom: 10px;
      div{
        padding: 0;
        line-height: 20px;
      }
    }
  }
  .el-icon-location{
    color: #409EFF;
    font-size: 20px;
  }
}
</style>
